import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import LoginModal from "./LoginModal"; // Assuming you have a LoginModal component

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility

  const handleModalOpen = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <Box textAlign="center" py={100} px={6}>
      <Heading as="h1" size="4xl" mb={6} color="gray.800">
        Welcome to Condeck
      </Heading>
      <Text fontSize="xl" mb={8} color="gray.600">
        A simple way to redistribute your digital content across all platforms.
      </Text>
      <UnorderedList textAlign="left" spacing={4} mb={8} mx="auto" maxW="md">
        <ListItem>
          <Text as="span" fontWeight="bold">
            Centralized Content Management:{" "}
          </Text>
          Easily upload and manage your digital content from a single platform.
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="bold">
            Cross-Platform Distribution:{" "}
          </Text>
          Share your content seamlessly across various platforms like social
          media, blogs, and websites.
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="bold">
            Analytics and Insights:{" "}
          </Text>
          Gain valuable insights into your content's performance and audience
          engagement.
        </ListItem>
      </UnorderedList>
      <Button
        colorScheme="blue"
        size="lg"
        boxShadow="md"
        borderColor="#222222"
        borderWidth={3}
        borderBottomWidth={5}
        borderRightWidth={5}
        borderRadius={10}
        onClick={handleModalOpen} // Open the modal when the button is clicked
      >
        Sign In To Get Started
      </Button>
      <LoginModal
        isOpen={isModalOpen} // Pass the modal visibility state
        onClose={handleModalClose} // Pass the function to close the modal
      />
    </Box>
  );
};

export default Home;