// LoginModal.js
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { auth, googleProvider, db } from "../firebase";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";

const externalLinks = [
  {
    name: "Facebook",
    url: "https://facebook.com",
  },
  {
    name: "Instagram",
    url: "https://instagram.com",
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com",
  },
  {
    name: "Tiktok",
    url: "https://tiktok.com",
  },
  {
    name: "X (Twitter)",
    url: "https://X.com",
  },
  {
    name: "YouTube",
    url: "https://youtube.com",
  },
];

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const createUserInFirestore = async (user) => {
    try {
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        await setDoc(userRef, {
          name: user.displayName || "",
          email: user.email,
          tiktokUsername: "tiktok",
          instagramUsername: "instagram",
          externalLinks, // Add the externalLinks array here
        });
        console.log("User created in Firestore");
      } else {
        console.log("User already exists in Firestore");
      }
    } catch (error) {
      console.error("Error creating user in Firestore:", error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      await createUserInFirestore(user);
      onClose();
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const handleEmailLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await createUserInFirestore(user);
      onClose();
    } catch (error) {
      console.error("Error during email login:", error);
      // Handle login error, e.g., display an error message to the user
    }
  };

  const handleEmailSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      await createUserInFirestore(user);
      onClose();
    } catch (error) {
      console.error("Error during email sign up:", error);
      // Handle sign up error, e.g., display an error message to the user
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p={4} pb={10}>
        <ModalHeader>Sign In</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <HStack spacing={4} width="100%">
              <Button
                colorScheme="blue"
                onClick={handleEmailLogin}
                flex="1"
                borderColor="#222222"
                borderWidth={3}
                borderBottomWidth={5}
                borderRightWidth={5}
                borderRadius={10}
                h={12}
              >
                Sign In with Email
              </Button>
              <Button
                colorScheme="blue"
                onClick={handleEmailSignUp}
                flex="1"
                borderColor="#222222"
                borderWidth={3}
                borderBottomWidth={5}
                borderRightWidth={5}
                borderRadius={10}
                h={12}
              >
                Sign Up with Email
              </Button>
            </HStack>
            <Button
              borderColor="#222222"
              h={12}
              borderWidth={3}
              borderBottomWidth={5}
              borderRightWidth={5}
              borderRadius={10}
              colorScheme="white"
              color="#222222"
              onClick={handleGoogleLogin}
              width="100%"
              leftIcon={<Icon as={FcGoogle} boxSize={6} />}
            >
              Sign In with Google
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LoginModal;
