import React from 'react';
import { Box, Flex, Text, Link } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box as="footer" py={10} mt={100} borderTopWidth={2} borderColor="#222222">
      <Flex align="center" justify="space-between" maxW="container.lg" mx="auto" px={4}>
        <Flex align="center">
          <Text fontSize="xl" fontWeight="bold" letterSpacing={'-.1rem'}>🚀Condeck</Text>
        </Flex>
        <Link href="https://hrznlabs.com" isExternal>
          <Text fontSize="sm">Made by HRZN Labs</Text>
        </Link>
      </Flex>
    </Box>
  );
};

export default Footer;