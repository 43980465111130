import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, Link, SimpleGrid, Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const Links = () => {
  const [user, setUser] = useState(null);
  const [links, setLinks] = useState([]);
  const [linkName, setLinkName] = useState('');
  const [linkUrl, setLinkUrl] = useState('');
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setLinks(userDoc.data().externalLinks || []);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleAddLink = async () => {
    if (linkName && linkUrl) {
      const newLink = { name: linkName, url: linkUrl };
      const updatedLinks = [...links, newLink];
      setLinks(updatedLinks);
      setLinkName('');
      setLinkUrl('');

      if (user) {
        await updateDoc(doc(db, 'users', user.uid), {
          externalLinks: updatedLinks,
        });
      }
    }
  };

  const handleDeleteLink = async () => {
    if (selectedLinkIndex !== null) {
      const updatedLinks = links.filter((_, i) => i !== selectedLinkIndex);
      setLinks(updatedLinks);
      setSelectedLinkIndex(null);

      if (user) {
        await updateDoc(doc(db, 'users', user.uid), {
          externalLinks: updatedLinks,
        });
      }
    }
  };

  const getLinkIcon = (url) => {
    if (url.includes('facebook')) {
      return <FaFacebook />;
    } else if (url.includes('instagram')) {
      return <FaInstagram />;
    } else if (url.includes('linkedin')) {
      return <FaLinkedin />;
    }
    return null;
  };

  const getLinkColor = (url) => {
    if (url.includes('facebook')) {
      return 'blue.500';
    } else if (url.includes('instagram')) {
      return 'pink.500';
    } else if (url.includes('linkedin')) {
      return 'blue.600';
    }
    return 'gray';
  };

  return (
    <Box display="flex" justifyContent="center" mt={20}>
      <Box w="600px">
        <SimpleGrid columns={3} spacing={4} mb={8}>
          {links.map((link, index) => (
            <Popover
              key={index}
              isOpen={selectedLinkIndex === index}
              onClose={() => setSelectedLinkIndex(null)}
              placement="top"
              closeOnBlur={true}
            >
              <PopoverTrigger>
                <Button
                  as={Link}
                  href={link.url}
                  isExternal
                  size="md"
                  borderWidth={3}
                  borderRadius={10}
                  borderBottomWidth={5}
                  borderRightWidth={5}
                  borderColor={getLinkColor(link.url)}
                  leftIcon={getLinkIcon(link.url)}
                  w="auto"
                  px={4}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setSelectedLinkIndex(index);
                  }}
                >
                  {link.name}
                </Button>
              </PopoverTrigger>
              <PopoverContent w="auto">
                <PopoverBody>
                  <Button colorScheme="red" size="sm" onClick={handleDeleteLink}>
                    Delete
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ))}
        </SimpleGrid>
        <Flex mb={4} justifyContent="center">
          <FormControl mr={2}>
            <FormLabel>Link Name</FormLabel>
            <Input
              type="text"
              value={linkName}
              onChange={(e) => setLinkName(e.target.value)}
              placeholder="Enter link name"
            />
          </FormControl>
          <FormControl mr={2}>
            <FormLabel>Link URL</FormLabel>
            <Input
              type="url"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              placeholder="Enter link URL"
            />
          </FormControl>
          <Button onClick={handleAddLink} colorScheme="blue" mt={8} w="300px">
            <AddIcon mr={2} />
            Add Link
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default Links;