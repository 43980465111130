import React, { useState, useEffect } from 'react';
import { Box, Heading, Button, Input, Text, IconButton, Flex, Center, Spinner, useToast } from '@chakra-ui/react';
import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FaDownload, FaCopy } from 'react-icons/fa';
import { saveAs } from 'file-saver';

const Main = () => {
  const [user, setUser] = useState(null);
  const [tiktokUsername, setTiktokUsername] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const [tiktokVideoUrl, setTiktokVideoUrl] = useState('');
  const [tiktokCaption, setTiktokCaption] = useState('');
  const [instagramVideoUrl, setInstagramVideoUrl] = useState('');
  const [instagramCaption, setInstagramCaption] = useState('');
  const [activeTab, setActiveTab] = useState('tiktok');
  const [tiktokLoading, setTiktokLoading] = useState(false);
  const [instagramLoading, setInstagramLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setTiktokUsername(userDoc.data().tiktokUsername);
          setInstagramUsername(userDoc.data().instagramUsername);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleFetchTiktokVideo = async () => {
    setTiktokLoading(true);
    setTiktokVideoUrl('');
    setTiktokCaption('');
    setActiveTab('tiktok');

    const url = `https://tiktok-video-no-watermark10.p.rapidapi.com/index/Tiktok/getUserVideos?unique_id=${tiktokUsername}&count=1`;
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
        'X-RapidAPI-Host': 'tiktok-video-no-watermark10.p.rapidapi.com',
      },
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(response)
      const video = result.data.videos[0];
      setTiktokVideoUrl(video.play);
      setTiktokCaption(video.title);
      setTiktokLoading(false);
    } catch (error) {
      console.error(error);
      setTiktokLoading(false);
    }
  };

  const handleTiktokUsernameChange = async (e) => {
    const newUsername = e.target.value;
    setTiktokUsername(newUsername);
    if (user) {
      await updateDoc(doc(db, 'users', user.uid), {
        tiktokUsername: newUsername,
      });
    }
  };

  const handleInstagramUsernameChange = async (e) => {
    const newUsername = e.target.value;
    setInstagramUsername(newUsername);
    if (user) {
      await updateDoc(doc(db, 'users', user.uid), {
        instagramUsername: newUsername,
      });
    }
  };

  const handleFetchInstagramContent = async () => {
    setInstagramLoading(true);
    setInstagramVideoUrl('');
    setInstagramCaption('');
    setActiveTab('instagram');

    const url = `https://instagram-scraper-api2.p.rapidapi.com/v1.2/posts?username_or_id_or_url=${instagramUsername}`;
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': process.env.REACT_APP_RAPID_API_KEY,
        'X-RapidAPI-Host': 'instagram-scraper-api2.p.rapidapi.com',
      },
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(response)
      const reelItem = result.data.items.find(item => item.media_name === 'reel');
      if (reelItem) {
        setInstagramVideoUrl(reelItem.video_url);
        setInstagramCaption(reelItem.caption.text);
      }
      setInstagramLoading(false);
    } catch (error) {
      console.error(error);
      setInstagramLoading(false);
    }
  };

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100000000);
  };

  const handleDownload = async (url, platform) => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
      const randomNumber = generateRandomNumber();
      const username = platform === 'tiktok' ? tiktokUsername : instagramUsername;
      const cleanedUsername = username.replace('@', '');
      const fileName = `${cleanedUsername}_${platform}_${randomNumber}.mp4`;
      saveAs(data, fileName);
    } catch (error) {
      console.error('Failed to download the file:', error);
    }
  };

  const handleCopyCaption = (caption) => {
    navigator.clipboard.writeText(caption);
    toast({
      title: 'Copied to clipboard',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Center>
      <Box>
        <Heading textAlign="center" mt={10}>Choose Your Platform</Heading>
        <Box mt={6} w="600px">
          <Flex justify="center">
            <Box mt={4} mr={4} w="250px">
              <Heading size="md">TikTok Username:</Heading>
              <Input value={tiktokUsername} onChange={handleTiktokUsernameChange} placeholder="TikTok username" />
              <Button mt={2} w="250px" colorScheme="blue" onClick={handleFetchTiktokVideo} borderWidth={3} borderColor="#222222" borderBottomWidth={5} borderRightWidth={5} borderRadius={10}>
                Fetch TikTok Video
              </Button>
            </Box>
            <Box mt={4} w="250px">
              <Heading size="md">Instagram Username:</Heading>
              <Input value={instagramUsername} onChange={handleInstagramUsernameChange} placeholder="Instagram username" />
              <Button w="250px" mt={2} colorScheme="blue" onClick={handleFetchInstagramContent} borderWidth={3} borderColor="#222222" borderBottomWidth={5} borderRightWidth={5} borderRadius={10}>
                Fetch Instagram Reel
              </Button>
            </Box>
          </Flex>

          {activeTab === 'tiktok' && (
            <Box mt={8}>
              {tiktokLoading ? (
                <Center>
                  <Spinner size="xl" />
                </Center>
              ) : (
                <Flex align="center">
                  {tiktokVideoUrl && (
                    <Box flex="1" mr={4}>
                      <Center>
                        <video controls style={{ width: '200px', borderRadius: '10px' }}>
                          <source src={tiktokVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </Center>
                      <Button
                        leftIcon={<FaDownload />}
                        onClick={() => handleDownload(tiktokVideoUrl, 'tiktok')}
                        mt={4}
                        w="300px"
                        borderWidth={3}
                        borderColor="#222222"
                        borderBottomWidth={5}
                        borderRightWidth={5}
                        borderRadius={10}
                      >
                        Download TikTok Video
                      </Button>
                    </Box>
                  )}
                  {tiktokCaption && (
                    <Box flex="1">
                      <Heading size="md">TikTok Caption:</Heading>
                      <Text>{tiktokCaption}</Text>
                      <IconButton
                        icon={<FaCopy />}
                        aria-label="Copy Caption"
                        onClick={() => handleCopyCaption(tiktokCaption)}
                        mt={2}
                        borderWidth={3}
                        borderColor="#222222"
                        borderBottomWidth={5}
                        borderRightWidth={5}
                        borderRadius={10}
                      />
                    </Box>
                  )}
                </Flex>
              )}
            </Box>
          )}

          {activeTab === 'instagram' && (
            <Box mt={8}>
              {instagramLoading ? (
                <Center>
                  <Spinner size="xl" />
                </Center>
              ) : (
                <Flex align="center">
                  {instagramVideoUrl && (
                    <Box flex="1" mr={4}>
                      <Center>
                        <video controls style={{ width: '200px', borderRadius: '10px' }}>
                          <source src={instagramVideoUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </Center>
                      <Button
                        leftIcon={<FaDownload />}
                        onClick={() => handleDownload(instagramVideoUrl, 'instagram')}
                        mt={4}
                        borderWidth={3}
                        borderColor="#222222"
                        borderBottomWidth={5}
                        borderRightWidth={5}
                        borderRadius={10}
                        w="300px"
                      >
                        Download Instagram Video
                      </Button>
                    </Box>
                  )}
                  {instagramCaption && (
                    <Box flex="1">
                      <Heading size="md">Instagram Caption:</Heading>
                      <Text>{instagramCaption}</Text>
                      <IconButton
                        icon={<FaCopy />}
                        aria-label="Copy Caption"
                        onClick={() => handleCopyCaption(instagramCaption)}
                        mt={2}
                        borderWidth={3}
                        borderColor="#222222"
                        borderBottomWidth={5}
                        borderRightWidth={5}
                        borderRadius={10}
                      />
                    </Box>
                  )}
                </Flex>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Center>
  );
};

export default Main;