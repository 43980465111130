import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC9H39iza7jaTzrgtY9P8da5chCxQpDMr4",
  authDomain: "condecktest.firebaseapp.com",
  projectId: "condecktest",
  storageBucket: "condecktest.appspot.com",
  messagingSenderId: "100964403018",
  appId: "1:100964403018:web:2aa63f8310d48b6e90e3b3",
  measurementId: "G-DMH946B0RT"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };