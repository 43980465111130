import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Links from './components/Links';
import Main from './components/Main';
import { auth } from './firebase';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(user !== null);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <Navbar />
      {isAuthenticated ? (
        <>
          <Main />
          <Links />
        </>
      ) : (
        <Home />
      )}
      <Footer />
    </>
  );
};

export default App;