// Navbar.js
import React, { useState, useEffect } from 'react';
import { Flex, Box, Spacer, Heading, Button } from '@chakra-ui/react';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import LoginModal from './LoginModal';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  return (
    <Flex as="nav" align="center" justify="space-between" wrap="wrap" padding="1.5rem" borderBottomWidth={2} borderColor="#222222">
      <Flex align="center" mr={5}>
        <Heading as="h1" size="lg" letterSpacing={'-.1rem'}>
          🚀Condeck
        </Heading>
      </Flex>
      <Spacer />
      <Box>
        {user ? (
          <>
            <span>{user.displayName}</span>
            <Button colorScheme="red" ml={4} onClick={handleLogout} borderWidth={3} borderColor="#222222" borderBottomWidth={5} borderRightWidth={5} borderRadius={10}>
              Logout
            </Button>
          </>
        ) : (
          <Button colorScheme="blue" onClick={openLoginModal} borderWidth={3} borderColor="#222222" borderBottomWidth={5} borderRightWidth={5} borderRadius={10}>
            Sign In
          </Button>
        )}
      </Box>
      <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
    </Flex>
  );
};

export default Navbar;